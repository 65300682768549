import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import { useStyles } from "../styles";
import { useTranslation } from "react-i18next";
import Input from "components/Input";
import Button from "components/Button";
import Icon from "components/Icon";
import AddImage from "assets/icons/Add_Image.svg";
import CloseImage from "assets/icons/Close.svg";
import * as Yup from "yup";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { useDispatch } from "react-redux";
import { getUser } from "services/authService";

export function FormCommunityStyled(props) {
  const { t } = useTranslation();
  const [isForm, setIsForm] = useState(false);
  const user = getUser();
  const dispatch = useDispatch();
  const {
    isMd,
    isTabCommunity,
    handlePostCommunity,
    isSuccessPost,
  } = props;
  const inputFileRef = useRef();
  const classes = useStyles({ mediaQueryMd: isMd, isTabCommunity });

  const communityScheme = Yup.object().shape({
    title: Yup.string()
      .required(t("components.community.Title required"))
      .test(
        "required",
        t("components.community.Title required"),
        (val) => !!val?.trim().length
      )
      .max(100, t("components.community.max characters", { max: 100 })),
    content: Yup.string()
      .required(t("components.community.Content requiredd"))
      .test(
        "required",
        t("components.community.Content required"),
        (val) => !!val?.trim().length
      )
      .max(300, t("components.community.max characters", { max: 300 })),
    file: Yup.mixed(),
  });

  const initiallValueCommunity = {
    title: "",
    content: "",
    file: null,
    text:t(`components.editorImage.use`),
  };

  const formikFormUser = useFormik({
    initialValues: initiallValueCommunity,
    validationSchema: communityScheme,
    enableReinitialize: true,
  });
  const formPublish = () => {
    return (
      <form className={classes.form}>
        <Input
          isSucess={
            !formikFormUser.errors.title &&
            formikFormUser.touched.title &&
            formikFormUser.dirty
          }
          isError={formikFormUser.errors.title && formikFormUser.touched.title}
          name="title"
          label={t(`components.community.Title`)}
          isRequired
          css={classes.input}
          id="txt_title_community"
          onChange={formikFormUser.handleChange}
          onBlur={formikFormUser.handleBlur}
          message={formikFormUser.errors.title}
          value={formikFormUser.values.title}
        />
        <Input
          isSucess={
            !formikFormUser.errors.content &&
            formikFormUser.touched.content &&
            formikFormUser.dirty
          }
          isError={
            formikFormUser.errors.content && formikFormUser.touched.content
          }
          name="content"
          label={t(`components.community.Content`)}
          isRequired
          css={classes.input}
          id="txt_content_community"
          onChange={formikFormUser.handleChange}
          onBlur={formikFormUser.handleBlur}
          message={formikFormUser.errors.content}
          value={formikFormUser.values.content}
          multiline
          rows={6}
        />
        <div className={classes.uploadImageContent}>
          <div>
            <Button
              iconLeft={<Icon icon={AddImage} />}
              id="btn_upload_image_community"
              type="cuaternary"
              text="components.community.Add Image"
              onClick={() => {
                inputFileRef.current.click();
              }}
              css={classes.uploadImage}
            />
          </div>

          {formikFormUser.values?.file?.name ? (
            <div className={classes.file}>
              <p>{t(`components.community.Uploaded Image`)}</p>
              <p>{formikFormUser.values?.file?.name}</p>
              <Icon
                onClick={() => formikFormUser.setFieldValue("file", null)}
                icon={CloseImage}
              />
            </div>
          ) : (<p className={classes.helper}>{t(`components.community.allowed image format`)}</p>)}
          <input
            onChange={(event) => {
              if (event.target.files[0])
                formikFormUser.setFieldValue(
                  "file",
                  event.currentTarget.files[0]
                );
            }}
            style={{ display: "none" }}
            type="file"
            accept=".png, .jpg, .svg"
            ref={inputFileRef}
          />
        </div>

        <div>
          <Button
            id="btn_handlePost"
            disabled={!(formikFormUser.isValid && formikFormUser.dirty)}
            onClick={() => {
              if (user) {
                handlePostCommunity(formikFormUser.values);
                formikFormUser.resetForm();
              } else {
                dispatch({
                  type: "@modal/login",
                  payload: { open: true },
                });
              }
            }}
            type="secondary"
            text={t(`components.community.Publish Prayer`)}
            css={classes.buttonPublish}
          />
        </div>
      </form>
    );
  };

  return (
    <div className={classes.formContent}>
      <h3 data-testid="community_title">
        {t(`components.community.Community`)}
      </h3>
      <h1 data-testid="community_subtitle">
        {t(`components.community.Share your prayer`)}
      </h1>
      <p data-testid="community_description">
        {t(
          `components.community.Write your prayer and share it with the other members of the Pope’s Worldwide Prayer Network`
        )}
      </p>
      {isSuccessPost ? (
        <div className={classes.successContent}>
          <div>
            <CheckCircleOutlineIcon />
            <p>
              {t(
                "components.community.Your prayer was shared with the community"
              )}
            </p>
          </div>
          <div>
            <p>{t(`components.community.Thank you! We pray together`)}</p>
          </div>
        </div>
      ) : (
        <>
          {isMd && isForm && formPublish()}
          {!isMd && formPublish()}
          {!isForm && isMd && (
            <div>
              <Button
                id="button_write_prayer"
                onClick={() => setIsForm(true)}
                type="secondary"
                text={t(`components.community.Write a Prayer`)}
                css={classes.buttonPublish}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
