import { backgrounds, parsePrayer, setAmen, unsetAmen } from "../../helpers";
import { getIntention, getPrayer } from "services/services";
import moment from "moment";
import PrayerMorningIcon from "assets/icons/PrayerMorning.svg";
import PrayerEveningIcon from "assets/icons/PrayerEvening.svg";
import PrayerAfternoonIcon from "assets/icons/PrayerAfternoon.svg";
import { findAmen } from "services/authService";

export function getTime(time) {
  switch (true) {
    case time < 13:
    case 24 <= time:
      return 0;
    case time < 18:
    case 13 >= time:
      return 1;
    case time < 24:
    case 18 <= time:
      return 2;
  }
}
export async function fetchIntention(
  date,
  setBackgroundImage,
  setPrayers,
  setDateIntention,
  mediaQueryMd,
  prayers,
  setPrayersWeek
) {
  try {
    const response = await getIntention({
      date: date,
      prayersCode: "mor,aft,eve",
    });
    if (mediaQueryMd)
      setBackgroundImage(backgrounds(response?.multimedia, "IMG_INT_HME_MBL"));
    else
      setBackgroundImage(backgrounds(response?.multimedia, "IMG_INT_HME_WEB"));
    const prayersList = response?.prayers?.map((prayer) => parsePrayer(prayer));
    const prayerParse = prayers?.map((prayer) => {
      let prayerFind = prayersList.find(
        (prayerList) => prayerList.typeMode === prayer.typeMode
      );

      if (prayerFind) {
        return {
          ...prayer,
          description: prayerFind.description,
          audio: prayerFind.audio,
          isPrayer: true,
          id: prayerFind.id,
          typeForAmen: prayerFind.typeForAmen,
          groupData: prayerFind?.groupData,
        };
      } else return prayer;
    });

    setPrayers(prayerParse);
    setDateIntention({
      date: response?.prayers_date,
      day: moment(response?.prayers_date).format("dddd"),
      dayNumber: moment(response?.prayers_date).date(),
      month: moment(response?.prayers_date).format("MMMM").toUpperCase(),
      year: moment(response?.prayers_date).format("YYYY"),
      video: "",
    });

    fetchPrayerWeek(response?.prayers_date, setPrayersWeek);
  } catch (error) {
    console.log(error);
  }
}
export function getCurrentWeek(current) {
  // isoWeek for start on Monday
  const startWeek = moment(current)
    .clone()
    .startOf("isoWeek")
    .format("YYYY-MM-DD");

  const weekFormat = [];
  for (let i = 0; i < 7; i++) {
    const dateObject = moment(startWeek).clone().add(i, "days");
    weekFormat.push({
      date: dateObject.format("YYYY-MM-DD"),
      prayers: [
        {
          icon: PrayerMorningIcon,
          title: "components.prayCard.With Jesus in the morning",
          type: "components.prayCard.Start your day",
          typeMode: "morning",
          textHover: "components.prayCard.Morning Prayer",
          audio: null,
          description: `pages.daily.There is no loaded prayer for this moment`,
          isPrayer: false,
          code: "MOR",
        },
        {
          icon: PrayerAfternoonIcon,
          title: "components.prayCard.With Jesus in the Afternoon",
          type: "components.prayCard.Take a break",
          typeMode: "afternoon",
          textHover: "components.prayCard.Afternoon Prayer",
          audio: null,
          description: `pages.daily.There is no loaded prayer for this moment`,
          isPrayer: false,
          code: "AFT",
        },
        {
          icon: PrayerEveningIcon,
          title: "components.prayCard.With Jesus at night",
          type: "components.prayCard.Contemplate and rest",
          typeMode: "evening",
          textHover: "components.prayCard.Evening Prayer",
          audio: null,
          description: `pages.daily.There is no loaded prayer for this moment`,
          isPrayer: false,
          code: "EVE",
        },
      ],
      day: dateObject.format("dddd"),
      dayNumber: dateObject.date(),
      month: dateObject.format("MMMM").toLowerCase(),
    });
  }
  return weekFormat;
}
export async function fetchPrayerWeek(date, setPrayersWeek) {
  try {
    const prayersWeek = getCurrentWeek(date);
    const response = await getPrayer({
      date_from: prayersWeek[0].date,
      date_to: prayersWeek[6].date,
      type: "mor,aft,eve",
      per_page: 21,
    });
    if (response?.data) {
      const prayersWeekUpdate = prayersWeek.map((prayerweek) => {
        const datePrayers = response?.data.filter(
          (prayer) => prayer.show_date.split('T')[0] === prayerweek.date
        );
        if (datePrayers.length > 0) {
          const prayersUpdate = prayerweek.prayers.map((pray) => {
            const momentPrayer = datePrayers.find(
              (prayer) => prayer.type.code === pray.code
            );
            // if exists a prayer for momentDay, parsed it, else return a basic pray model
            return momentPrayer ? parsePrayer(momentPrayer) : pray;
          });
          prayerweek.prayers = prayersUpdate;
        }
        return prayerweek;
      });
      setPrayersWeek(prayersWeekUpdate);
    }
  } catch (error) {
    console.log(error);
  }
}
export function handleClickPrayCard(
  pray,
  mediaQueryMd,
  prayersWeek,
  positionWeek,
  setSinglePrayer,
  setIsModalSinglePrayer
) {
  if (mediaQueryMd) {
    let prayWeek = prayersWeek[positionWeek];
    setSinglePrayer(
      prayWeek.prayers.find((prayer) => prayer.typeMode === pray.typeMode)
    );
    setIsModalSinglePrayer(true);
  } else {
    if (
      pray.description ===
      "pages.daily.There is no loaded prayer for this moment"
    ) {
      pray.isPrayer = false;
    } else pray.isPrayer = true;
    setSinglePrayer(pray);
    setIsModalSinglePrayer(true);
  }
}

const amenCheck = (prayer) => {
  return !!findAmen(prayer?.id, prayer.typeForAmen);
};

export async function setFavorite(
  owner,
  ownerType,
  index,
  prayers,
  setPrayers,
  singlePrayer,
  setSinglePrayer
) {
  await setAmen(owner, ownerType);
  if (index === null) {
    setSinglePrayer({ ...singlePrayer, isFavorite: amenCheck(singlePrayer) });
  } else {
    let items = [...prayers];
    items[index].isFavorite = amenCheck(items[index]);
    setPrayers(items);
  }
}

export async function handleDislike(
  owner,
  ownerType,
  index,
  prayers,
  setPrayers,
  singlePrayer,
  setSinglePrayer
) {
  await unsetAmen(owner, ownerType);
  if (index === null) {
    setSinglePrayer({ ...singlePrayer, isFavorite: amenCheck(singlePrayer) });
  } else {
    let items = [...prayers];
    items[index].isFavorite = amenCheck(items[index]);
    setPrayers(items);
  }
}
