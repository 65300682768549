import React, { useState, useEffect } from "react";
import { handleMultimedia } from "../../helpers";
import { useTranslation } from "react-i18next";
import moment from "moment";
import DailyView from "./DailyView";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PrayerMorningIcon from "assets/icons/PrayerMorning.svg";
import PrayerEveningIcon from "assets/icons/PrayerEvening.svg";
import PrayerAfternoonIcon from "assets/icons/PrayerAfternoon.svg";
import { getTime, fetchIntention, handleClickPrayCard, setFavorite, handleDislike } from "./DailyFunctions";
import { useParams } from "react-router-dom";
// import SinglePrayer from "components/SinglePrayer";

export function DailyContainer() {
  const { t } = useTranslation();
  const { prayer_id, prayer_date } = useParams();
  const mediaQuerySm = useMediaQuery("(max-width:600px)");
  const mediaQueryMd = useMediaQuery("(max-width:960px)");
  const [backgroundImage, setBackgroundImage] = useState({
    url: null,
    color: null,
  });
  const [dateIntention, setDateIntention] = useState({
    day: "",
    dayNumber: "",
    month: "",
  });
  const [Audioplay, setAudioPlay] = useState(false);
  const [AudioplayWeek, setAudioPlayWeek] = useState(false);
  const [prayers, setPrayers] = useState([
    {
      icon: PrayerMorningIcon,
      title: "components.prayCard.With Jesus in the morning",
      type: "components.prayCard.Start your day",
      typeMode: "morning",
      textHover: "components.prayCard.Morning Prayer",
      audio: null,
      description: `pages.daily.There is no loaded prayer for this moment`,
      isPrayer: false,
    },
    {
      icon: PrayerAfternoonIcon,
      title: "components.prayCard.With Jesus in the Afternoon",
      type: "components.prayCard.Take a break",
      typeMode: "afternoon",
      textHover: "components.prayCard.Afternoon Prayer",
      audio: null,
      description: `pages.daily.There is no loaded prayer for this moment`,
      isPrayer: false,
    },
    {
      icon: PrayerEveningIcon,
      title: "components.prayCard.With Jesus at night",
      type: "components.prayCard.Contemplate and rest",
      typeMode: "evening",
      textHover: "components.prayCard.Evening Prayer",
      audio: null,
      description: `pages.daily.There is no loaded prayer for this moment`,
      isPrayer: false,
    },
  ]);
  const [positionPrayer, setPositionPrayer] = useState(0);
  const [prayersWeek, setPrayersWeek] = useState([]);
  const [isNextWeek, setIsNextWeek] = useState(true);
  const [isBackWeek, setIsBackWeek] = useState(false);
  const [positionWeek, setPositionWeek] = useState(0);
  const [isModalSinglePrayer, setIsModalSinglePrayer] = useState(false);
  const [singlePrayer, setSinglePrayer] = useState({
    description: "",
    title: "",
    icon: "",
  });

  useEffect(() => {
    setPositionPrayer(getTime(moment().hour()));
    let intentionDate = prayer_date ?? moment().format("YYYY-MM-DD");
    fetchIntention(
      intentionDate,
      setBackgroundImage,
      setPrayers,
      setDateIntention,
      mediaQueryMd,
      prayers,
      setPrayersWeek
    );
  }, [t]);

  useEffect(() => {
    if (prayer_id) {
      let prayerIndex;
      const prayerType = prayer_id.split('.')[0];
      const prayerId = prayer_id.split('.')[1];
      switch (prayerType.toUpperCase()) {
        case 'MOR':
          prayerIndex = 0;
          break;
        case 'AFT':
          prayerIndex = 1;
          break;
        case 'EVE':
          prayerIndex = 2;
          break;
      }
      setPositionPrayer(prayerIndex);
      const filteredPrayer = prayers?.find(prayer => prayer.id === +prayerId);
      setSinglePrayer(filteredPrayer)
      setIsModalSinglePrayer(true)
      return () => {
        setPositionPrayer(0);
        setIsModalSinglePrayer(false);
      }
    }
  }, [prayers]);

  return (
    <>
      <DailyView
        dateIntention={dateIntention}
        prayers={prayers}
        backgroundImage={backgroundImage}
        isSm={mediaQuerySm}
        isMd={mediaQueryMd}
        handleMultimedia={(audio, type) => {
          setAudioPlay(handleMultimedia(audio, type, Audioplay));
        }}
        isPlay={Audioplay}
        isPlayWeek={AudioplayWeek}
        positionPrayer={positionPrayer}
        handleNext={(index) => {
          setAudioPlay(false);
          setPositionPrayer(index + 1);
        }}
        handleBack={(index) => {
          setAudioPlay(false);
          setPositionPrayer(index - 1);
        }}
        prayersWeek={prayersWeek}
        onScrollWeek={(event) => {
          if (event.target.scrollLeft !== 0) {
            setIsBackWeek(true);
          } else {
            setIsBackWeek(false);
          }
          if (
            event.target.scrollWidth - event.target.offsetWidth !==
            event.target.scrollLeft
          ) {
            setIsNextWeek(true);
          } else {
            setIsNextWeek(false);
          }
        }}
        isNextWeek={isNextWeek}
        isBackWeek={isBackWeek}
        handleClickPrayCard={(pray) => {
          setAudioPlay(false);
          handleClickPrayCard(
            pray,
            mediaQueryMd,
            prayersWeek,
            positionWeek,
            setSinglePrayer,
            setIsModalSinglePrayer
          );
        }}
        handleNextWeek={() => setPositionWeek(positionWeek + 1)}
        handleBackWeek={() => setPositionWeek(positionWeek - 1)}
        positionWeek={positionWeek}
        handleCloseSinglePrayer={() => {
          setIsModalSinglePrayer(false);
        }}
        isModalSinglePrayer={isModalSinglePrayer}
        singlePrayer={singlePrayer}
        handleMultimediaWeek={(audio, type) => {
          setAudioPlayWeek(handleMultimedia(audio, type, AudioplayWeek));
        }}
        handleFavorite={(owner, ownerType, index) => setFavorite(owner, ownerType, index, prayers, setPrayers, singlePrayer, setSinglePrayer)}
        handleDislike={(owner, ownerType, index) => handleDislike(owner, ownerType, index, prayers, setPrayers, singlePrayer, setSinglePrayer)}
      />

      {/* {filteredPrayer && <SinglePrayer
        dateIntention={prayer_date}
        isModal={true}
        isDaily
        description={
          !filteredPrayer?.isPrayer
            ? t(filteredPrayer?.description)
            : filteredPrayer?.description
        }
        title={filteredPrayer?.title}
        icon={filteredPrayer?.icon}
        textHover={filteredPrayer?.textHover}
        audio={filteredPrayer?.audio}
        handleMultimedia={handleMultimedia}
        isPlay={Audioplay}
        isSmall={mediaQuerySm}
        open={isModalSinglePrayer}
        isPrayer={filteredPrayer?.isPrayer}
        handleFavorite={() => {
          setFavorite(filteredPrayer?.id, filteredPrayer?.typeForAmen, null, prayers, setPrayers, singlePrayer, setSinglePrayer)
        }}
        handleClose={() => {
          setIsModalSinglePrayer(false);
        }}
        handleDislike={() => {
          handleDislike(filteredPrayer?.id, filteredPrayer?.typeForAmen, null, prayers, setPrayers, singlePrayer, setSinglePrayer);
        }}
        owner={filteredPrayer?.id}
        ownerType={filteredPrayer.typeForAmen} style={{ marginTop: '10rem' }} />} */}
    </>
  );
}
